var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", { ref: "main", staticClass: "bg-light" }, [
    _c(
      "div",
      { staticClass: "d-flex", staticStyle: { "min-height": "100%" } },
      [
        _vm.$store.getters.hasFeature("BRAND_CREATE")
          ? _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c(
                        "div",
                        { staticClass: "section-title decorative-border-left" },
                        [
                          _c("h1", [
                            _vm._v(_vm._s(_vm.$t("createBrand.header.title"))),
                          ]),
                          _c("h3", [
                            _vm._v(
                              _vm._s(_vm.$t("createBrand.header.description"))
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-stepper",
                          {
                            attrs: { flat: "", "non-linear": "" },
                            model: {
                              value: _vm.step,
                              callback: function ($$v) {
                                _vm.step = $$v
                              },
                              expression: "step",
                            },
                          },
                          [
                            _vm.$vuetify.breakpoint.mdAndUp
                              ? _c(
                                  "v-stepper-header",
                                  { staticClass: "elevation-0" },
                                  [
                                    _c(
                                      "v-stepper-step",
                                      {
                                        attrs: {
                                          editable: _vm.completedStep >= 0,
                                          "complete-icon": "fa-light fa-check",
                                          "edit-icon": "fa-light fa-check",
                                          step: 1,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpToStep(1)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "createBrand.wizard.description"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-stepper-step",
                                      {
                                        attrs: {
                                          editable: _vm.completedStep >= 1,
                                          "complete-icon": "fa-light fa-check",
                                          "edit-icon": "fa-light fa-check",
                                          step: 2,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpToStep(2)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "createBrand.wizard.nameAndHeader"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-stepper-step",
                                      {
                                        attrs: {
                                          editable: _vm.completedStep >= 2,
                                          "complete-icon": "fa-light fa-check",
                                          "edit-icon": "fa-light fa-check",
                                          step: 3,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpToStep(3)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "createBrand.wizard.regions"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-stepper-step",
                                      {
                                        attrs: {
                                          editable: _vm.completedStep >= 3,
                                          "complete-icon": "fa-light fa-check",
                                          "edit-icon": "fa-light fa-check",
                                          step: 4,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpToStep(4)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "createBrand.wizard.classifications"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("v-divider"),
                                    _c(
                                      "v-stepper-step",
                                      {
                                        attrs: {
                                          editable: _vm.completedStep >= 4,
                                          "complete-icon": "fa-light fa-check",
                                          "edit-icon": "fa-light fa-check",
                                          step: 5,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.jumpToStep(5)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "createBrand.wizard.summary"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-stepper-items",
                              [
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: 1 } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          column: "",
                                          "align-center": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            class: {
                                              "pa-10":
                                                _vm.$vuetify.breakpoint.mdAndUp,
                                            },
                                            attrs: {
                                              elevation: "3",
                                              color: "white",
                                              rounded: "",
                                              width: _vm.$vuetify.breakpoint.lg
                                                ? "75%"
                                                : _vm.$vuetify.breakpoint.xl
                                                ? "50%"
                                                : "100%",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [
                                                    _vm._v(
                                                      "fa-light fa-input-text"
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.description.title"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("v-divider"),
                                            _c("v-card-subtitle", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "createBrand.description.subtitle"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "d-flex flex-column",
                                              },
                                              [
                                                _c("v-textarea", {
                                                  attrs: {
                                                    outlined: "",
                                                    autocomplete: "on",
                                                    rows: "6",
                                                    "auto-grow": "",
                                                    label: _vm.$t(
                                                      "createBrand.description.labelTextField"
                                                    ),
                                                    "hide-details": "",
                                                  },
                                                  model: {
                                                    value: _vm.description,
                                                    callback: function ($$v) {
                                                      _vm.description = $$v
                                                    },
                                                    expression: "description",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-actions",
                                              {
                                                staticClass:
                                                  "mt-10 d-flex justify-end",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                      disabled:
                                                        !this.description,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(2)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.wizard.buttonNext"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-right"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: 2 } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          column: "",
                                          "align-center": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            class: {
                                              "pa-10":
                                                _vm.$vuetify.breakpoint.mdAndUp,
                                            },
                                            attrs: {
                                              elevation: "3",
                                              color: "white",
                                              rounded: "",
                                              width: _vm.$vuetify.breakpoint.lg
                                                ? "75%"
                                                : _vm.$vuetify.breakpoint.xl
                                                ? "50%"
                                                : "100%",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [
                                                    _vm._v(
                                                      "fa-light fa-input-text"
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.name.title"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("v-divider"),
                                            _c(
                                              "v-card-subtitle",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.name.subtitle"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("ExplainButton", {
                                                  attrs: {
                                                    icon: true,
                                                    color: "amber darken-2",
                                                    chatInstance:
                                                      _vm.chatInstance,
                                                    message: _vm.$t(
                                                      "createBrand.name.explainMessage"
                                                    ),
                                                  },
                                                  on: {
                                                    posted: function ($event) {
                                                      _vm.chatOpened = true
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "d-flex flex-column",
                                              },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    outlined: "",
                                                    label: _vm.$t(
                                                      "createBrand.name.labelTextField"
                                                    ),
                                                    "hide-details": "",
                                                  },
                                                  model: {
                                                    value: _vm.name,
                                                    callback: function ($$v) {
                                                      _vm.name = $$v
                                                    },
                                                    expression: "name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-title",
                                              { staticClass: "mt-7" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [_vm._v("fa-light fa-image")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.logo.title"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("v-divider"),
                                            _c(
                                              "v-card-subtitle",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.logo.subtitle"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("ExplainButton", {
                                                  attrs: {
                                                    icon: true,
                                                    color: "amber darken-2",
                                                    chatInstance:
                                                      _vm.chatInstance,
                                                    message: _vm.$t(
                                                      "createBrand.logo.explainMessage"
                                                    ),
                                                  },
                                                  on: {
                                                    posted: function ($event) {
                                                      _vm.chatOpened = true
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "d-flex flex-column",
                                              },
                                              [
                                                _c("ImagePicker", {
                                                  attrs: {
                                                    title: _vm.$t(
                                                      "createBrand.logo.labelLogoPicker"
                                                    ),
                                                    "max-preview-height": "300",
                                                  },
                                                  model: {
                                                    value: _vm.logo,
                                                    callback: function ($$v) {
                                                      _vm.logo = $$v
                                                    },
                                                    expression: "logo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-actions",
                                              {
                                                staticClass:
                                                  "mt-10 d-flex justify-space-between",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(1)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-left"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.wizard.buttonBack"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                      disabled:
                                                        !this
                                                          .selectedBrandTypeKey,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(3)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.wizard.buttonNext"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-right"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: 3 } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          column: "",
                                          "align-center": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            class: {
                                              "pa-10":
                                                _vm.$vuetify.breakpoint.mdAndUp,
                                            },
                                            attrs: {
                                              elevation: "3",
                                              color: "white",
                                              rounded: "",
                                              loading:
                                                _vm.registrationOfficeCodes
                                                  .isBusy,
                                              width: _vm.$vuetify.breakpoint.lg
                                                ? "75%"
                                                : _vm.$vuetify.breakpoint.xl
                                                ? "50%"
                                                : "100%",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [
                                                    _vm._v(
                                                      "fa-light fa-map-pin"
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.offices.title"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("v-divider"),
                                            _c(
                                              "v-card-subtitle",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.offices.subtitle"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("ExplainButton", {
                                                  attrs: {
                                                    icon: true,
                                                    color: "amber darken-2",
                                                    chatInstance:
                                                      _vm.chatInstance,
                                                    message: _vm.$t(
                                                      "createBrand.offices.explainMessage"
                                                    ),
                                                  },
                                                  on: {
                                                    posted: function ($event) {
                                                      _vm.chatOpened = true
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "d-flex flex-column align-center",
                                              },
                                              [
                                                _c(
                                                  "v-chip-group",
                                                  {
                                                    attrs: {
                                                      multiple: "",
                                                      column: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedRegistrationOfficeCodes,
                                                      callback: function ($$v) {
                                                        _vm.selectedRegistrationOfficeCodes =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedRegistrationOfficeCodes",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.registrationOfficeCodes
                                                      .list,
                                                    function (office) {
                                                      return _c(
                                                        "FeatureBadge",
                                                        {
                                                          key: office.key,
                                                          staticStyle: {
                                                            "z-index": "1",
                                                          },
                                                          attrs: {
                                                            small: "",
                                                            overlap: "",
                                                            "offset-x": "20",
                                                            "offset-y": "13",
                                                            hasFeature:
                                                              _vm.hasOfficeFeature(
                                                                office
                                                              ),
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-chip",
                                                            {
                                                              attrs: {
                                                                label: "",
                                                                filter: "",
                                                                value: office,
                                                                disabled:
                                                                  !_vm.hasOfficeFeature(
                                                                    office
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                staticClass:
                                                                  "my-auto mr-2",
                                                                attrs: {
                                                                  src: _vm.$braendz.registrationOfficeCodeFlag(
                                                                    office.key
                                                                  ),
                                                                  contain: "",
                                                                  "max-height":
                                                                    "24",
                                                                  "max-width":
                                                                    "24",
                                                                },
                                                              }),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    office.shortDescription
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-actions",
                                              {
                                                staticClass:
                                                  "mt-10 d-flex justify-space-between",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(2)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-left"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.wizard.buttonBack"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                      disabled:
                                                        this
                                                          .selectedRegistrationOfficeCodes
                                                          .length <= 0,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(4)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.wizard.buttonNext"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-right"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: 4 } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "mb-2",
                                        attrs: {
                                          column: "",
                                          "align-center": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            class: {
                                              "pa-10":
                                                _vm.$vuetify.breakpoint.mdAndUp,
                                            },
                                            attrs: {
                                              elevation: "3",
                                              color: "white",
                                              rounded: "",
                                              width: _vm.$vuetify.breakpoint.lg
                                                ? "75%"
                                                : _vm.$vuetify.breakpoint.xl
                                                ? "50%"
                                                : "100%",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [_vm._v("fa-light fa-grid-2")]
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.classification.title"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("v-divider"),
                                            _c(
                                              "v-card-subtitle",
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.classification.subtitle"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("ExplainButton", {
                                                  attrs: {
                                                    icon: true,
                                                    color: "amber darken-2",
                                                    chatInstance:
                                                      _vm.chatInstance,
                                                    message: _vm.$t(
                                                      "createBrand.classification.explainMessage"
                                                    ),
                                                  },
                                                  on: {
                                                    posted: function ($event) {
                                                      _vm.chatOpened = true
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-tabs",
                                                  {
                                                    attrs: {
                                                      "background-color":
                                                        "transparent",
                                                      centered: "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedClassificationTab,
                                                      callback: function ($$v) {
                                                        _vm.selectedClassificationTab =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedClassificationTab",
                                                    },
                                                  },
                                                  [
                                                    _c("v-tab", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "createBrand.classification.tabAiSelection.title"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c("v-tab", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "createBrand.classification.tabManualSelection.title"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-tabs-items",
                                                  {
                                                    staticClass: "my-5",
                                                    model: {
                                                      value:
                                                        _vm.selectedClassificationTab,
                                                      callback: function ($$v) {
                                                        _vm.selectedClassificationTab =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedClassificationTab",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-tab-item",
                                                      { staticClass: "pa-1" },
                                                      [
                                                        _c(
                                                          "v-container",
                                                          {
                                                            attrs: {
                                                              fluid: "",
                                                            },
                                                          },
                                                          [
                                                            _vm
                                                              .goodsServicesSemanticQueryResult
                                                              .object &&
                                                            _vm
                                                              .goodsServicesSemanticQueryResult
                                                              .object.items
                                                              ? _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      [
                                                                        _c(
                                                                          "v-subheader",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "createBrand.classification.tabAiSelection.selectGoodsAndServicesLabel"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-chip-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                column:
                                                                                  "",
                                                                                multiple:
                                                                                  "",
                                                                                "active-class":
                                                                                  "primary primary--text",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.selectedGoodsServices,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.selectedGoodsServices =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "selectedGoodsServices",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-expansion-panels",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    multiple:
                                                                                      "",
                                                                                    flat: "",
                                                                                    value:
                                                                                      _vm.goodsServicesSemanticSearchResultOpenedPanels,
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.groupedGoodsServicesSemanticQueryResult,
                                                                                function (
                                                                                  group,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    "v-expansion-panel",
                                                                                    {
                                                                                      key: index,
                                                                                    },
                                                                                    [
                                                                                      group.niceClass
                                                                                        ? _c(
                                                                                            "v-expansion-panel-header",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-0",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-list-item",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "px-0",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      dense:
                                                                                                        "",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-list-item-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-1",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "justify-center",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              small:
                                                                                                                "",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              _vm.$braendz.getNiceClassIcon(
                                                                                                                group
                                                                                                                  .niceClass
                                                                                                                  .key
                                                                                                              )
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-list-item-content",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-list-item-title",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "font-weight-medium",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                group
                                                                                                                  .niceClass
                                                                                                                  .shortDescription
                                                                                                              ) +
                                                                                                              " - " +
                                                                                                              _vm._s(
                                                                                                                _vm.$t(
                                                                                                                  "createBrand.classification.niceClass"
                                                                                                                )
                                                                                                              ) +
                                                                                                              " " +
                                                                                                              _vm._s(
                                                                                                                group
                                                                                                                  .niceClass
                                                                                                                  .key
                                                                                                              ) +
                                                                                                              " "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "v-expansion-panel-content",
                                                                                        {
                                                                                          staticClass:
                                                                                            "px-0",
                                                                                        },
                                                                                        _vm._l(
                                                                                          group.resultItems,
                                                                                          function (
                                                                                            goodOrServiceResultItem,
                                                                                            index
                                                                                          ) {
                                                                                            return _c(
                                                                                              "v-chip",
                                                                                              {
                                                                                                key: index,
                                                                                                attrs:
                                                                                                  {
                                                                                                    label:
                                                                                                      "",
                                                                                                    outlined:
                                                                                                      "",
                                                                                                    filter:
                                                                                                      "",
                                                                                                    value:
                                                                                                      goodOrServiceResultItem.indexItem,
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                goodOrServiceResultItem.indexItem
                                                                                                  ? [
                                                                                                      _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "text-truncate",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              goodOrServiceResultItem
                                                                                                                .indexItem
                                                                                                                .description
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  : _vm._e(),
                                                                                              ],
                                                                                              2
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-tab-item",
                                                      [
                                                        _c(
                                                          "v-container",
                                                          {
                                                            attrs: {
                                                              fluid: "",
                                                            },
                                                          },
                                                          [
                                                            _vm
                                                              .groupedSelectedGoodsServices
                                                              .length > 0
                                                              ? _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      [
                                                                        _c(
                                                                          "v-chip-group",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                column:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-expansion-panels",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    multiple:
                                                                                      "",
                                                                                    flat: "",
                                                                                    value:
                                                                                      _vm.selectedGoodsServicesOpenedPanels,
                                                                                  },
                                                                              },
                                                                              _vm._l(
                                                                                _vm.groupedSelectedGoodsServices,
                                                                                function (
                                                                                  group,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    "v-expansion-panel",
                                                                                    {
                                                                                      key: index,
                                                                                    },
                                                                                    [
                                                                                      group.niceClass
                                                                                        ? _c(
                                                                                            "v-expansion-panel-header",
                                                                                            {
                                                                                              staticClass:
                                                                                                "py-0",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-list-item",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "px-0",
                                                                                                  attrs:
                                                                                                    {
                                                                                                      dense:
                                                                                                        "",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-list-item-icon",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "mr-1",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "justify-center",
                                                                                                          attrs:
                                                                                                            {
                                                                                                              small:
                                                                                                                "",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              _vm.$braendz.getNiceClassIcon(
                                                                                                                group
                                                                                                                  .niceClass
                                                                                                                  .key
                                                                                                              )
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-list-item-content",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-list-item-title",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "font-weight-medium",
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                group
                                                                                                                  .niceClass
                                                                                                                  .shortDescription
                                                                                                              ) +
                                                                                                              " - " +
                                                                                                              _vm._s(
                                                                                                                _vm.$t(
                                                                                                                  "createBrand.classification.niceClass"
                                                                                                                )
                                                                                                              ) +
                                                                                                              " " +
                                                                                                              _vm._s(
                                                                                                                group
                                                                                                                  .niceClass
                                                                                                                  .key
                                                                                                              ) +
                                                                                                              " "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "v-expansion-panel-content",
                                                                                        _vm._l(
                                                                                          group.goodsServices,
                                                                                          function (
                                                                                            goodOrService,
                                                                                            index
                                                                                          ) {
                                                                                            return _c(
                                                                                              "v-chip",
                                                                                              {
                                                                                                key: index,
                                                                                                attrs:
                                                                                                  {
                                                                                                    label:
                                                                                                      "",
                                                                                                    close:
                                                                                                      "",
                                                                                                    value:
                                                                                                      goodOrService,
                                                                                                  },
                                                                                                on: {
                                                                                                  "click:close":
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.removeSelectedGoodOrService(
                                                                                                        goodOrService
                                                                                                      )
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      goodOrService.description
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          }
                                                                                        ),
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                }
                                                                              ),
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "createBrand.classification.tabManualSelection.emptyList"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                            _c(
                                                              "v-row",
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    staticClass:
                                                                      "d-flex justify-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "AddGoodOrServicePopup",
                                                                      {
                                                                        on: {
                                                                          added:
                                                                            _vm.addGoodOrService,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function (
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                plain:
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          "v-btn",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                left: "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "fa-light fa-plus"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "createBrand.classification.tabManualSelection.buttonAdd"
                                                                                                )
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            1894778420
                                                                          ),
                                                                        model: {
                                                                          value:
                                                                            _vm.showAddGoodOrServicePopup,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.showAddGoodOrServicePopup =
                                                                                $$v
                                                                            },
                                                                          expression:
                                                                            "showAddGoodOrServicePopup",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-actions",
                                              {
                                                staticClass:
                                                  "mt-10 d-flex justify-space-between",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(3)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-left"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.wizard.buttonBack"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      light: "",
                                                      color: "tertiary",
                                                      disabled:
                                                        _vm
                                                          .selectedGoodsServices
                                                          .length === 0,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setStep(5)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "createBrand.wizard.buttonSummary"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "fa-light fa-chevron-right"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-stepper-content",
                                  { attrs: { step: 5 } },
                                  [
                                    _c(
                                      "v-container",
                                      {
                                        staticClass: "pa-0 mb-2",
                                        attrs: { fluid: "" },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  xl: "3",
                                                  lg: "4",
                                                  md: "6",
                                                  sm: "12",
                                                  cols: "12",
                                                },
                                              },
                                              [
                                                _c("dashboard-card", {
                                                  attrs: {
                                                    title: _vm.$t(
                                                      "createBrand.configuredBrand.title"
                                                    ),
                                                    subtitle: _vm.$t(
                                                      "createBrand.configuredBrand.subtitle"
                                                    ),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "content",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "v-card-title",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "block",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item",
                                                                  {
                                                                    attrs: {
                                                                      "two-line":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list-item-content",
                                                                      [
                                                                        _vm.selectedBrandType &&
                                                                        _vm
                                                                          .selectedBrandType
                                                                          .key !==
                                                                          "Figurative"
                                                                          ? _c(
                                                                              "TruncateTooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    label:
                                                                                      _vm.name,
                                                                                    top: "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-list-item-title",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-h5 text-truncate",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.name
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _c(
                                                                              "v-list-item-title",
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "$no-text"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                        _vm.selectedBrandType &&
                                                                        _vm
                                                                          .selectedBrandType
                                                                          .description
                                                                          ? _c(
                                                                              "v-list-item-subtitle",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .selectedBrandType
                                                                                        .description
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm.selectedBrandType
                                                                          ? _c(
                                                                              "v-list-item-subtitle",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .selectedBrandType
                                                                                        .key
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "v-list-item-subtitle",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "brand.tile.valueNotAvailable"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "mx-3",
                                                            }),
                                                            _c(
                                                              "v-card-text",
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "BrandDetailsTextField",
                                                                          {
                                                                            staticClass:
                                                                              "pa-3",
                                                                            attrs:
                                                                              {
                                                                                title:
                                                                                  _vm.$t(
                                                                                    "createBrand.configuredBrand.countries"
                                                                                  ),
                                                                              },
                                                                          },
                                                                          [
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                {
                                                                                  staticClass:
                                                                                    "ma-0",
                                                                                  attrs:
                                                                                    {
                                                                                      row: "",
                                                                                      "justify-start":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                _vm._l(
                                                                                  _vm.selectedRegistrationOfficeCodes,
                                                                                  function (
                                                                                    registrationOfficeCode,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        key: index,
                                                                                        staticClass:
                                                                                          "mr-2",
                                                                                        attrs:
                                                                                          {
                                                                                            shrink:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-avatar",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                tile: "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            registrationOfficeCode.description
                                                                                              ? _c(
                                                                                                  "v-tooltip",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        bottom:
                                                                                                          "",
                                                                                                      },
                                                                                                    scopedSlots:
                                                                                                      _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key: "activator",
                                                                                                            fn: function (
                                                                                                              ref
                                                                                                            ) {
                                                                                                              var on =
                                                                                                                ref.on
                                                                                                              var attrs =
                                                                                                                ref.attrs
                                                                                                              return [
                                                                                                                registrationOfficeCode.key
                                                                                                                  ? _c(
                                                                                                                      "v-img",
                                                                                                                      _vm._g(
                                                                                                                        _vm._b(
                                                                                                                          {
                                                                                                                            attrs:
                                                                                                                              {
                                                                                                                                src: _vm.$braendz.registrationOfficeCodeFlag(
                                                                                                                                  registrationOfficeCode.key
                                                                                                                                ),
                                                                                                                                contain:
                                                                                                                                  "",
                                                                                                                                "max-height":
                                                                                                                                  "50",
                                                                                                                                "max-width":
                                                                                                                                  "50",
                                                                                                                              },
                                                                                                                          },
                                                                                                                          "v-img",
                                                                                                                          attrs,
                                                                                                                          false
                                                                                                                        ),
                                                                                                                        on
                                                                                                                      )
                                                                                                                    )
                                                                                                                  : _vm._e(),
                                                                                                              ]
                                                                                                            },
                                                                                                          },
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      ),
                                                                                                  },
                                                                                                  [
                                                                                                    registrationOfficeCode
                                                                                                      ? _c(
                                                                                                          "span",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                registrationOfficeCode.description
                                                                                                              )
                                                                                                            ),
                                                                                                          ]
                                                                                                        )
                                                                                                      : _vm._e(),
                                                                                                  ]
                                                                                                )
                                                                                              : registrationOfficeCode.key
                                                                                              ? _c(
                                                                                                  "v-img",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        src: _vm.$braendz.registrationOfficeCodeFlag(
                                                                                                          registrationOfficeCode.key
                                                                                                        ),
                                                                                                        contain:
                                                                                                          "",
                                                                                                        "max-height":
                                                                                                          "50",
                                                                                                        "max-width":
                                                                                                          "50",
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                1
                                                                              ),
                                                                            ],
                                                                          ],
                                                                          2
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "mx-3",
                                                            }),
                                                            _c(
                                                              "v-card-text",
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "150px",
                                                                      },
                                                                    attrs: {
                                                                      align:
                                                                        "center",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex justify-center",
                                                                        attrs: {
                                                                          cols: "12",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm.logo
                                                                          ? _c(
                                                                              "v-img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: _vm.logoUrl,
                                                                                    contain:
                                                                                      "",
                                                                                    "max-height":
                                                                                      "100",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    size: "100",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "fa-light fa-image-slash"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-divider", {
                                                              staticClass:
                                                                "mx-3",
                                                            }),
                                                            _c(
                                                              "v-card-text",
                                                              [
                                                                _c(
                                                                  "v-row",
                                                                  [
                                                                    _c(
                                                                      "v-col",
                                                                      [
                                                                        _c(
                                                                          "BrandDetailsTextField",
                                                                          {
                                                                            staticClass:
                                                                              "pa-3",
                                                                            attrs:
                                                                              {
                                                                                title:
                                                                                  _vm.$t(
                                                                                    "createBrand.configuredBrand.goodsAndServices"
                                                                                  ),
                                                                                titleTop: true,
                                                                              },
                                                                          },
                                                                          [
                                                                            [
                                                                              _c(
                                                                                "v-list",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "transparent",
                                                                                    },
                                                                                },
                                                                                _vm._l(
                                                                                  _vm.groupedSelectedGoodsServices,
                                                                                  function (
                                                                                    goodsServicesGroup,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "v-list-item",
                                                                                      {
                                                                                        key: index,
                                                                                      },
                                                                                      [
                                                                                        goodsServicesGroup.niceClass
                                                                                          ? _c(
                                                                                              "v-list-item-avatar",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "align-self-start",
                                                                                              },
                                                                                              [
                                                                                                goodsServicesGroup
                                                                                                  .niceClass
                                                                                                  .description
                                                                                                  ? _c(
                                                                                                      "v-tooltip",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            bottom:
                                                                                                              "",
                                                                                                          },
                                                                                                        scopedSlots:
                                                                                                          _vm._u(
                                                                                                            [
                                                                                                              {
                                                                                                                key: "activator",
                                                                                                                fn: function (
                                                                                                                  ref
                                                                                                                ) {
                                                                                                                  var on =
                                                                                                                    ref.on
                                                                                                                  var attrs =
                                                                                                                    ref.attrs
                                                                                                                  return [
                                                                                                                    goodsServicesGroup.niceClass
                                                                                                                      ? _c(
                                                                                                                          "v-chip",
                                                                                                                          _vm._g(
                                                                                                                            _vm._b(
                                                                                                                              {
                                                                                                                                attrs:
                                                                                                                                  {
                                                                                                                                    small:
                                                                                                                                      "",
                                                                                                                                    outlined:
                                                                                                                                      "",
                                                                                                                                  },
                                                                                                                              },
                                                                                                                              "v-chip",
                                                                                                                              attrs,
                                                                                                                              false
                                                                                                                            ),
                                                                                                                            on
                                                                                                                          ),
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "span",
                                                                                                                              {
                                                                                                                                staticClass:
                                                                                                                                  "body-1",
                                                                                                                              },
                                                                                                                              [
                                                                                                                                _vm._v(
                                                                                                                                  _vm._s(
                                                                                                                                    goodsServicesGroup
                                                                                                                                      .niceClass
                                                                                                                                      .key
                                                                                                                                  )
                                                                                                                                ),
                                                                                                                              ]
                                                                                                                            ),
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      : _vm._e(),
                                                                                                                  ]
                                                                                                                },
                                                                                                              },
                                                                                                            ],
                                                                                                            null,
                                                                                                            true
                                                                                                          ),
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                goodsServicesGroup
                                                                                                                  .niceClass
                                                                                                                  .description
                                                                                                              )
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  : _c(
                                                                                                      "v-chip",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            small:
                                                                                                              "",
                                                                                                            outlined:
                                                                                                              "",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "body-1",
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                goodsServicesGroup
                                                                                                                  .niceClass
                                                                                                                  .key
                                                                                                              )
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _c(
                                                                                          "v-list-item-content",
                                                                                          [
                                                                                            goodsServicesGroup
                                                                                              .goodsServices
                                                                                              .length >
                                                                                            0
                                                                                              ? _c(
                                                                                                  "v-list-item-title",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "wrap-text",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " " +
                                                                                                        _vm._s(
                                                                                                          goodsServicesGroup.goodsServices
                                                                                                            .map(
                                                                                                              function (
                                                                                                                gs
                                                                                                              ) {
                                                                                                                return gs.description
                                                                                                              }
                                                                                                            )
                                                                                                            .join(
                                                                                                              "; "
                                                                                                            )
                                                                                                        ) +
                                                                                                        " "
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              : _c(
                                                                                                  "v-list-item-title",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "font-italic text--disabled",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "brand.tile.valueNotAvailable"
                                                                                                        )
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                1
                                                                              ),
                                                                            ],
                                                                          ],
                                                                          2
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1561534078
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass: "pa-0",
                                                attrs: {
                                                  xl: "9",
                                                  lg: "8",
                                                  md: "6",
                                                  sm: "12",
                                                  cols: "12",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass:
                                                      "fill-height pa-0 ma-0",
                                                  },
                                                  [
                                                    !_vm.noName
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              xl: "4",
                                                              lg: "6",
                                                              md: "12",
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "dashboard-card",
                                                              {
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "createBrand.refusalGrounds.title"
                                                                  ),
                                                                  subtitle:
                                                                    _vm.$t(
                                                                      "createBrand.refusalGrounds.subtitle"
                                                                    ),
                                                                  nodata:
                                                                    _vm.hasFeatureRefusalGrounds &&
                                                                    (!_vm
                                                                      .aiEvaluationRefusalGrounds
                                                                      .object ||
                                                                      !_vm
                                                                        .aiEvaluationRefusalGrounds
                                                                        .object
                                                                        .content),
                                                                  loading:
                                                                    _vm
                                                                      .aiEvaluationRefusalGrounds
                                                                      .isBusy,
                                                                  hasFeature:
                                                                    _vm.hasFeatureRefusalGrounds,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "content",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm
                                                                              .aiEvaluationRefusalGrounds
                                                                              .object &&
                                                                            _vm
                                                                              .aiEvaluationRefusalGrounds
                                                                              .object
                                                                              .content
                                                                              ? [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex column justify-center pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "Label",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              text: _vm.$t(
                                                                                                "createBrand.dashboards.risk"
                                                                                              ),
                                                                                              location:
                                                                                                "bottom",
                                                                                              align:
                                                                                                "center",
                                                                                              offsetY: 12,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "radial-bar",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  width: 130,
                                                                                                  height: 130,
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.risk"
                                                                                                    ),
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .aiEvaluationRefusalGrounds
                                                                                                      .object
                                                                                                      .content
                                                                                                      .score *
                                                                                                    100,
                                                                                                  showValue: true,
                                                                                                  startAngle:
                                                                                                    -135,
                                                                                                  endAngle: 135,
                                                                                                  valueColors:
                                                                                                    _vm.riskColors,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "PaywallOverlay",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              hasFeature:
                                                                                                _vm.hasFeatureRefusalGroundsExplanation,
                                                                                              "z-index": 1,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "body-1 completion-summary",
                                                                                              domProps:
                                                                                                {
                                                                                                  innerHTML:
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .aiEvaluationRefusalGrounds
                                                                                                        .object
                                                                                                        .content
                                                                                                        .message
                                                                                                    ),
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              : !_vm.hasFeatureRefusalGrounds
                                                                              ? [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex column justify-center pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "Label",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              text: _vm.$t(
                                                                                                "createBrand.dashboards.risk"
                                                                                              ),
                                                                                              location:
                                                                                                "bottom",
                                                                                              align:
                                                                                                "center",
                                                                                              offsetY: 12,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "radial-bar",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  width: 130,
                                                                                                  height: 130,
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.risk"
                                                                                                    ),
                                                                                                  value: 50,
                                                                                                  showValue: true,
                                                                                                  startAngle:
                                                                                                    -135,
                                                                                                  endAngle: 135,
                                                                                                  valueColors:
                                                                                                    _vm.riskColors,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-1 completion-summary",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " To receive a detailed AI explanation for the refusal grounds evaluation, including insights like distinctiveness and potential risks, you need the Professional subscription. This unlocks an AI-based assessment of your brand's eligibility for registration. "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-loading",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.loading"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-nodata",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.nodata"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "actions",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "thumbs-buttons",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    tooltip:
                                                                                      "bottom",
                                                                                    small:
                                                                                      "",
                                                                                    context:
                                                                                      "Create Brand Evaluation Refusal Grounds",
                                                                                    disabled:
                                                                                      !_vm.hasFeatureRefusalGrounds ||
                                                                                      !_vm
                                                                                        .aiEvaluationRefusalGrounds
                                                                                        .object ||
                                                                                      !_vm
                                                                                        .aiEvaluationRefusalGrounds
                                                                                        .object
                                                                                        .content,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    597498458
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !_vm.noName
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              xl: "4",
                                                              lg: "6",
                                                              md: "12",
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "dashboard-card",
                                                              {
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "createBrand.aiEvaluationBrandName.title"
                                                                  ),
                                                                  subtitle:
                                                                    _vm.$t(
                                                                      "createBrand.aiEvaluationBrandName.subtitle"
                                                                    ),
                                                                  nodata:
                                                                    _vm.hasFeatureNameEvaluation &&
                                                                    (!_vm
                                                                      .aiEvaluationBrandName
                                                                      .object ||
                                                                      !_vm
                                                                        .aiEvaluationBrandName
                                                                        .object
                                                                        .content),
                                                                  loading:
                                                                    _vm
                                                                      .aiEvaluationBrandName
                                                                      .isBusy,
                                                                  hasFeature:
                                                                    _vm.hasFeatureNameEvaluation,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "content",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm
                                                                              .aiEvaluationBrandName
                                                                              .object &&
                                                                            _vm
                                                                              .aiEvaluationBrandName
                                                                              .object
                                                                              .content
                                                                              ? [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex column justify-center pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "Label",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              text: _vm.$t(
                                                                                                "createBrand.dashboards.risk"
                                                                                              ),
                                                                                              location:
                                                                                                "bottom",
                                                                                              align:
                                                                                                "center",
                                                                                              offsetY: 12,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "radial-bar",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  width: 130,
                                                                                                  height: 130,
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.risk"
                                                                                                    ),
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .aiEvaluationBrandName
                                                                                                      .object
                                                                                                      .content
                                                                                                      .score *
                                                                                                    100,
                                                                                                  showValue: true,
                                                                                                  startAngle:
                                                                                                    -135,
                                                                                                  endAngle: 135,
                                                                                                  valueColors:
                                                                                                    _vm.riskColors,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "PaywallOverlay",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              hasFeature:
                                                                                                _vm.hasFeatureNameEvaluationExplanation,
                                                                                              "z-index": 1,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "body-1 completion-summary",
                                                                                              domProps:
                                                                                                {
                                                                                                  innerHTML:
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .aiEvaluationBrandName
                                                                                                        .object
                                                                                                        .content
                                                                                                        .message
                                                                                                    ),
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              : !_vm.hasFeatureNameEvaluation
                                                                              ? [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex column justify-center pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "Label",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              text: _vm.$t(
                                                                                                "createBrand.dashboards.risk"
                                                                                              ),
                                                                                              location:
                                                                                                "bottom",
                                                                                              align:
                                                                                                "center",
                                                                                              offsetY: 12,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "radial-bar",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  width: 130,
                                                                                                  height: 130,
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.risk"
                                                                                                    ),
                                                                                                  value: 50,
                                                                                                  showValue: true,
                                                                                                  startAngle:
                                                                                                    -135,
                                                                                                  endAngle: 135,
                                                                                                  valueColors:
                                                                                                    _vm.riskColors,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-1 completion-summary",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " To receive an AI evaluation of the linguistic and cultural connotations of your brand name, including insights into its significance and potential interpretations, you need the Professional subscription. This feature provides detailed analysis to refine your brand identity. "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-loading",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.loading"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-nodata",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.nodata"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "actions",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "thumbs-buttons",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    tooltip:
                                                                                      "bottom",
                                                                                    small:
                                                                                      "",
                                                                                    context:
                                                                                      "Create Brand Evaluation Name",
                                                                                    disabled:
                                                                                      !_vm.hasFeatureNameEvaluation ||
                                                                                      !_vm
                                                                                        .aiEvaluationBrandName
                                                                                        .object ||
                                                                                      !_vm
                                                                                        .aiEvaluationBrandName
                                                                                        .object
                                                                                        .content,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    643780424
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !_vm.noName
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              xl: "4",
                                                              lg: "6",
                                                              md: "12",
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "dashboard-card",
                                                              {
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "createBrand.brandNameSimilarities.title"
                                                                  ),
                                                                  subtitle:
                                                                    _vm.$t(
                                                                      "createBrand.brandNameSimilarities.subtitle"
                                                                    ),
                                                                  nodata:
                                                                    _vm.hasFeatureNameSimilarities &&
                                                                    (!_vm
                                                                      .brandQueryResult
                                                                      .object ||
                                                                      !_vm
                                                                        .brandQueryResult
                                                                        .object
                                                                        .score),
                                                                  loading:
                                                                    _vm
                                                                      .brandQueryResult
                                                                      .isBusy,
                                                                  hasFeature:
                                                                    _vm.hasFeatureNameSimilarities,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "content",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm
                                                                              .brandQueryResult
                                                                              .object &&
                                                                            _vm
                                                                              .brandQueryResult
                                                                              .object
                                                                              .score
                                                                              ? [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-row justify-space-around",
                                                                                        },
                                                                                        [
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .nameMaxSimilarityScore !==
                                                                                            undefined &&
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .nameMaxSimilarityScore !==
                                                                                            null
                                                                                            ? _c(
                                                                                                "Label",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      text: _vm.$t(
                                                                                                        "createBrand.dashboards.risk"
                                                                                                      ),
                                                                                                      location:
                                                                                                        "bottom",
                                                                                                      align:
                                                                                                        "center",
                                                                                                      offsetY: 12,
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "radial-bar",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          width: 130,
                                                                                                          height: 130,
                                                                                                          label:
                                                                                                            _vm.$t(
                                                                                                              "createBrand.dashboards.risk"
                                                                                                            ),
                                                                                                          value:
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .nameMaxSimilarityScore *
                                                                                                            100,
                                                                                                          showValue: true,
                                                                                                          startAngle:
                                                                                                            -135,
                                                                                                          endAngle: 135,
                                                                                                          valueColors:
                                                                                                            _vm.riskColors,
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .nameExactMatchCount !==
                                                                                            undefined &&
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .nameExactMatchCount !==
                                                                                            null
                                                                                            ? _c(
                                                                                                "Counter",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      titleSingular:
                                                                                                        _vm.$t(
                                                                                                          "createBrand.dashboards.conflict"
                                                                                                        ),
                                                                                                      titlePlural:
                                                                                                        _vm.$t(
                                                                                                          "createBrand.dashboards.conflicts"
                                                                                                        ),
                                                                                                      criticalNumber: 1,
                                                                                                      count:
                                                                                                        _vm
                                                                                                          .brandQueryResult
                                                                                                          .object
                                                                                                          .score
                                                                                                          .nameExactMatchCount,
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-layout",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              column:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .nameExactMatchCount !==
                                                                                            undefined &&
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .nameExactMatchCount !==
                                                                                            null
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex flex-row justify-space-between",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-1",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "createBrand.brandNameSimilarities.exactNameCount"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      class:
                                                                                                        {
                                                                                                          "body-1 font-weight-bold": true,
                                                                                                          "color-ok":
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .nameExactMatchCount ===
                                                                                                            0,
                                                                                                          "color-risky":
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .nameExactMatchCount >
                                                                                                            0,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .nameExactMatchCount
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .nameSimilarMatchCount !==
                                                                                            null &&
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .nameSimilarMatchCount !==
                                                                                            undefined
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex flex-row justify-space-between",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-1",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "createBrand.brandNameSimilarities.similarNameCount"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      class:
                                                                                                        {
                                                                                                          "body-1 font-weight-bold": true,
                                                                                                          "color-ok":
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .nameSimilarMatchCount ===
                                                                                                            0,
                                                                                                          "color-risky":
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .nameSimilarMatchCount >
                                                                                                            0,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .nameSimilarMatchCount
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              : !_vm.hasFeatureNameSimilarities
                                                                              ? [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-row justify-space-around",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "Label",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  text: _vm.$t(
                                                                                                    "createBrand.dashboards.risk"
                                                                                                  ),
                                                                                                  location:
                                                                                                    "bottom",
                                                                                                  align:
                                                                                                    "center",
                                                                                                  offsetY: 12,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "radial-bar",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      width: 130,
                                                                                                      height: 130,
                                                                                                      label:
                                                                                                        _vm.$t(
                                                                                                          "createBrand.dashboards.risk"
                                                                                                        ),
                                                                                                      value: 50,
                                                                                                      showValue: true,
                                                                                                      startAngle:
                                                                                                        -135,
                                                                                                      endAngle: 135,
                                                                                                      valueColors:
                                                                                                        _vm.riskColors,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _c(
                                                                                            "Counter",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  titleSingular:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.conflict"
                                                                                                    ),
                                                                                                  titlePlural:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.conflicts"
                                                                                                    ),
                                                                                                  criticalNumber: 1,
                                                                                                  count: 1,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-layout",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              column:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex flex-row justify-space-between",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createBrand.brandNameSimilarities.exactNameCount"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1 font-weight-bold color-risky",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "1"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex flex-row justify-space-between",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createBrand.brandNameSimilarities.similarNameCount"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1 font-weight-bold color-risky",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "1"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-loading",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.loading"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-nodata",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.nodata"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "actions",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    text: "",
                                                                                    disabled:
                                                                                      !_vm
                                                                                        .brandQueryResult
                                                                                        .object,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.showBrandQueryResult,
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        left: "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fa-light fa-grid-2"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "createBrand.dashboards.buttonDetails"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "thumbs-buttons",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    tooltip:
                                                                                      "bottom",
                                                                                    small:
                                                                                      "",
                                                                                    context:
                                                                                      "Create Brand Name Similarities",
                                                                                    disabled:
                                                                                      !_vm.hasFeatureNameSimilarities ||
                                                                                      !_vm
                                                                                        .brandQueryResult
                                                                                        .object,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    2650836905
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !_vm.noLogo
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              xl: "4",
                                                              lg: "6",
                                                              md: "12",
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "dashboard-card",
                                                              {
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "createBrand.logoSimilarities.title"
                                                                  ),
                                                                  subtitle:
                                                                    _vm.$t(
                                                                      "createBrand.logoSimilarities.subtitle"
                                                                    ),
                                                                  nodata:
                                                                    _vm.hasFeatureLogoSimilarities &&
                                                                    (!_vm
                                                                      .brandQueryResult
                                                                      .object ||
                                                                      !_vm
                                                                        .brandQueryResult
                                                                        .object
                                                                        .score),
                                                                  loading:
                                                                    _vm
                                                                      .brandQueryResult
                                                                      .isBusy,
                                                                  hasFeature:
                                                                    _vm.hasFeatureLogoSimilarities,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "content",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm
                                                                              .brandQueryResult
                                                                              .object &&
                                                                            _vm
                                                                              .brandQueryResult
                                                                              .object
                                                                              .score
                                                                              ? [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-row justify-space-around",
                                                                                        },
                                                                                        [
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .logoMaxSimilarityScore !==
                                                                                            undefined &&
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .logoMaxSimilarityScore !==
                                                                                            null
                                                                                            ? _c(
                                                                                                "Label",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      text: _vm.$t(
                                                                                                        "createBrand.dashboards.risk"
                                                                                                      ),
                                                                                                      location:
                                                                                                        "bottom",
                                                                                                      align:
                                                                                                        "center",
                                                                                                      offsetY: 12,
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "radial-bar",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          width: 130,
                                                                                                          height: 130,
                                                                                                          label:
                                                                                                            _vm.$t(
                                                                                                              "createBrand.dashboards.risk"
                                                                                                            ),
                                                                                                          value:
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .logoMaxSimilarityScore *
                                                                                                            100,
                                                                                                          showValue: true,
                                                                                                          startAngle:
                                                                                                            -135,
                                                                                                          endAngle: 135,
                                                                                                          valueColors:
                                                                                                            _vm.riskColors,
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .logoExactMatchCount !==
                                                                                            undefined &&
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .logoExactMatchCount !==
                                                                                            null
                                                                                            ? _c(
                                                                                                "Counter",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      titleSingular:
                                                                                                        _vm.$t(
                                                                                                          "createBrand.dashboards.conflict"
                                                                                                        ),
                                                                                                      titlePlural:
                                                                                                        _vm.$t(
                                                                                                          "createBrand.dashboards.conflicts"
                                                                                                        ),
                                                                                                      criticalNumber: 1,
                                                                                                      count:
                                                                                                        _vm
                                                                                                          .brandQueryResult
                                                                                                          .object
                                                                                                          .score
                                                                                                          .logoExactMatchCount,
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-layout",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              column:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .logoExactMatchCount !==
                                                                                            null &&
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .logoExactMatchCount !==
                                                                                            undefined
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex flex-row justify-space-between",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-1",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "createBrand.logoSimilarities.exactLogoCount"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      class:
                                                                                                        {
                                                                                                          "body-1 font-weight-bold": true,
                                                                                                          "color-ok":
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .logoExactMatchCount ===
                                                                                                            0,
                                                                                                          "color-risky":
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .logoExactMatchCount >
                                                                                                            0,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .logoExactMatchCount
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .logoSimilarMatchCount !==
                                                                                            null &&
                                                                                          _vm
                                                                                            .brandQueryResult
                                                                                            .object
                                                                                            .score
                                                                                            .logoSimilarMatchCount !==
                                                                                            undefined
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex flex-row justify-space-between",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-1",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "createBrand.logoSimilarities.similarLogoCount"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      class:
                                                                                                        {
                                                                                                          "body-1 font-weight-bold": true,
                                                                                                          "color-ok":
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .logoSimilarMatchCount ===
                                                                                                            0,
                                                                                                          "color-risky":
                                                                                                            _vm
                                                                                                              .brandQueryResult
                                                                                                              .object
                                                                                                              .score
                                                                                                              .logoSimilarMatchCount >
                                                                                                            0,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .brandQueryResult
                                                                                                            .object
                                                                                                            .score
                                                                                                            .logoSimilarMatchCount
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              : !_vm.hasFeatureLogoSimilarities
                                                                              ? [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-row justify-space-around",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "Label",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  text: _vm.$t(
                                                                                                    "createBrand.dashboards.risk"
                                                                                                  ),
                                                                                                  location:
                                                                                                    "bottom",
                                                                                                  align:
                                                                                                    "center",
                                                                                                  offsetY: 12,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "radial-bar",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      width: 130,
                                                                                                      height: 130,
                                                                                                      label:
                                                                                                        _vm.$t(
                                                                                                          "createBrand.dashboards.risk"
                                                                                                        ),
                                                                                                      value: 50,
                                                                                                      showValue: true,
                                                                                                      startAngle:
                                                                                                        -135,
                                                                                                      endAngle: 135,
                                                                                                      valueColors:
                                                                                                        _vm.riskColors,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _c(
                                                                                            "Counter",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  titleSingular:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.conflict"
                                                                                                    ),
                                                                                                  titlePlural:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.conflicts"
                                                                                                    ),
                                                                                                  criticalNumber: 1,
                                                                                                  count: 1,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-layout",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              column:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex flex-row justify-space-between",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createBrand.logoSimilarities.exactLogoCount"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1 font-weight-bold color-risky",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "1"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex flex-row justify-space-between",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createBrand.logoSimilarities.similarLogoCount"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1 font-weight-bold color-risky",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "1"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-loading",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.loading"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-nodata",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.nodata"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "actions",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    text: "",
                                                                                    disabled:
                                                                                      !_vm
                                                                                        .brandQueryResult
                                                                                        .object,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.showBrandQueryResult,
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        left: "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fa-light fa-grid-2"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "createBrand.dashboards.buttonDetails"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "thumbs-buttons",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    tooltip:
                                                                                      "bottom",
                                                                                    small:
                                                                                      "",
                                                                                    context:
                                                                                      "Create Brand Logo Similarities",
                                                                                    disabled:
                                                                                      !_vm.hasFeatureLogoSimilarities ||
                                                                                      !_vm
                                                                                        .brandQueryResult
                                                                                        .object,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    3006021637
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          xl: "4",
                                                          lg: "6",
                                                          md: "12",
                                                          cols: "12",
                                                        },
                                                      },
                                                      [
                                                        _c("dashboard-card", {
                                                          attrs: {
                                                            title: _vm.$t(
                                                              "createBrand.classificationSimilarities.title"
                                                            ),
                                                            subtitle: _vm.$t(
                                                              "createBrand.classificationSimilarities.subtitle"
                                                            ),
                                                            nodata:
                                                              _vm.hasFeatureClassificationSimilarities &&
                                                              (!_vm
                                                                .brandQueryResult
                                                                .object ||
                                                                !_vm
                                                                  .brandQueryResult
                                                                  .object
                                                                  .score),
                                                            loading:
                                                              _vm
                                                                .brandQueryResult
                                                                .isBusy,
                                                            hasFeature:
                                                              _vm.hasFeatureClassificationSimilarities,
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "content",
                                                                fn: function () {
                                                                  return [
                                                                    _vm
                                                                      .brandQueryResult
                                                                      .object &&
                                                                    _vm
                                                                      .brandQueryResult
                                                                      .object
                                                                      .score
                                                                      ? [
                                                                          _c(
                                                                            "v-card-text",
                                                                            {
                                                                              staticClass:
                                                                                "pt-1",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex flex-row justify-space-around",
                                                                                },
                                                                                [
                                                                                  _vm
                                                                                    .brandQueryResult
                                                                                    .object
                                                                                    .score
                                                                                    .goodsServicesMaxSimilarityScore !==
                                                                                    undefined &&
                                                                                  _vm
                                                                                    .brandQueryResult
                                                                                    .object
                                                                                    .score
                                                                                    .goodsServicesMaxSimilarityScore !==
                                                                                    null
                                                                                    ? _c(
                                                                                        "Label",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              text: _vm.$t(
                                                                                                "createBrand.dashboards.risk"
                                                                                              ),
                                                                                              location:
                                                                                                "bottom",
                                                                                              align:
                                                                                                "center",
                                                                                              offsetY: 12,
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "radial-bar",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  width: 130,
                                                                                                  height: 130,
                                                                                                  label:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.risk"
                                                                                                    ),
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .brandQueryResult
                                                                                                      .object
                                                                                                      .score
                                                                                                      .goodsServicesMaxSimilarityScore *
                                                                                                    100,
                                                                                                  showValue: true,
                                                                                                  startAngle:
                                                                                                    -135,
                                                                                                  endAngle: 135,
                                                                                                  valueColors:
                                                                                                    _vm.riskColors,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm
                                                                                    .brandQueryResult
                                                                                    .object
                                                                                    .score
                                                                                    .goodsServicesSimilarCount !==
                                                                                    undefined &&
                                                                                  _vm
                                                                                    .brandQueryResult
                                                                                    .object
                                                                                    .score
                                                                                    .goodsServicesSimilarCount !==
                                                                                    null
                                                                                    ? _c(
                                                                                        "Counter",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              titleSingular:
                                                                                                _vm.$t(
                                                                                                  "createBrand.dashboards.conflict"
                                                                                                ),
                                                                                              titlePlural:
                                                                                                _vm.$t(
                                                                                                  "createBrand.dashboards.conflicts"
                                                                                                ),
                                                                                              criticalNumber: 1,
                                                                                              count:
                                                                                                _vm
                                                                                                  .brandQueryResult
                                                                                                  .object
                                                                                                  .score
                                                                                                  .goodsServicesSimilarCount,
                                                                                            },
                                                                                        }
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-divider",
                                                                            {
                                                                              staticClass:
                                                                                "mx-3",
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "v-card-text",
                                                                            {
                                                                              staticClass:
                                                                                "fill-height",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      column:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm
                                                                                    .brandQueryResult
                                                                                    .object
                                                                                    .score
                                                                                    .goodsServicesSimilarCount !==
                                                                                    undefined &&
                                                                                  _vm
                                                                                    .brandQueryResult
                                                                                    .object
                                                                                    .score
                                                                                    .goodsServicesSimilarCount !==
                                                                                    null
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-row justify-space-between",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "body-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "createBrand.classificationSimilarities.similarGoodsServicesCount"
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                {
                                                                                                  "body-1 font-weight-bold": true,
                                                                                                  "color-ok":
                                                                                                    _vm
                                                                                                      .brandQueryResult
                                                                                                      .object
                                                                                                      .score
                                                                                                      .goodsServicesSimilarCount ===
                                                                                                    0,
                                                                                                  "color-risky":
                                                                                                    _vm
                                                                                                      .brandQueryResult
                                                                                                      .object
                                                                                                      .score
                                                                                                      .goodsServicesSimilarCount >
                                                                                                    0,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .brandQueryResult
                                                                                                    .object
                                                                                                    .score
                                                                                                    .goodsServicesSimilarCount
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                  _vm
                                                                                    .brandQueryResult
                                                                                    .object
                                                                                    .score
                                                                                    .niceClassesOverlapCount !==
                                                                                    undefined &&
                                                                                  _vm
                                                                                    .brandQueryResult
                                                                                    .object
                                                                                    .score
                                                                                    .niceClassesOverlapCount !==
                                                                                    null
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-row justify-space-between",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "body-1",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "createBrand.classificationSimilarities.equalNiceClassesCount"
                                                                                                  )
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                {
                                                                                                  "body-1 font-weight-bold": true,
                                                                                                  "color-ok":
                                                                                                    _vm
                                                                                                      .brandQueryResult
                                                                                                      .object
                                                                                                      .score
                                                                                                      .niceClassesOverlapCount ===
                                                                                                    0,
                                                                                                  "color-risky":
                                                                                                    _vm
                                                                                                      .brandQueryResult
                                                                                                      .object
                                                                                                      .score
                                                                                                      .niceClassesOverlapCount >
                                                                                                    0,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .brandQueryResult
                                                                                                    .object
                                                                                                    .score
                                                                                                    .niceClassesOverlapCount
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      : !_vm.hasFeatureClassificationSimilarities
                                                                      ? [
                                                                          _c(
                                                                            "v-card-text",
                                                                            {
                                                                              staticClass:
                                                                                "pt-1",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "d-flex flex-row justify-space-around",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "Label",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          text: _vm.$t(
                                                                                            "createBrand.dashboards.risk"
                                                                                          ),
                                                                                          location:
                                                                                            "bottom",
                                                                                          align:
                                                                                            "center",
                                                                                          offsetY: 12,
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "radial-bar",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              width: 130,
                                                                                              height: 130,
                                                                                              label:
                                                                                                _vm.$t(
                                                                                                  "createBrand.dashboards.risk"
                                                                                                ),
                                                                                              value: 50,
                                                                                              showValue: true,
                                                                                              startAngle:
                                                                                                -135,
                                                                                              endAngle: 135,
                                                                                              valueColors:
                                                                                                _vm.riskColors,
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "Counter",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          titleSingular:
                                                                                            _vm.$t(
                                                                                              "createBrand.dashboards.conflict"
                                                                                            ),
                                                                                          titlePlural:
                                                                                            _vm.$t(
                                                                                              "createBrand.dashboards.conflicts"
                                                                                            ),
                                                                                          criticalNumber: 1,
                                                                                          count: 1,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "v-divider",
                                                                            {
                                                                              staticClass:
                                                                                "mx-3",
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "v-card-text",
                                                                            {
                                                                              staticClass:
                                                                                "fill-height",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-layout",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      column:
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex flex-row justify-space-between",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-1",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "createBrand.classificationSimilarities.similarGoodsServicesCount"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-1 font-weight-bold color-risky",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "1"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex flex-row justify-space-between",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-1",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "createBrand.classificationSimilarities.equalNiceClassesCount"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-1 font-weight-bold color-risky",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "1"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "content-loading",
                                                                fn: function () {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "createBrand.dashboards.loading"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "content-nodata",
                                                                fn: function () {
                                                                  return [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "createBrand.dashboards.nodata"
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "actions",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          disabled:
                                                                            !_vm
                                                                              .brandQueryResult
                                                                              .object,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            _vm.showBrandQueryResult,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                left: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "fa-light fa-grid-2"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "createBrand.dashboards.buttonDetails"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-spacer"
                                                                    ),
                                                                    _c(
                                                                      "thumbs-buttons",
                                                                      {
                                                                        attrs: {
                                                                          tooltip:
                                                                            "bottom",
                                                                          small:
                                                                            "",
                                                                          context:
                                                                            "Create Brand Classification Similarities",
                                                                          disabled:
                                                                            !_vm.hasFeatureClassificationSimilarities ||
                                                                            !_vm
                                                                              .brandQueryResult
                                                                              .object,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            2153945534
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    !_vm.noName
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              xl: "4",
                                                              lg: "6",
                                                              md: "12",
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "dashboard-card",
                                                              {
                                                                attrs: {
                                                                  title: _vm.$t(
                                                                    "createBrand.domainSummary.title"
                                                                  ),
                                                                  subtitle:
                                                                    _vm.$t(
                                                                      "createBrand.domainSummary.subtitle"
                                                                    ),
                                                                  nodata:
                                                                    _vm.hasFeatureDomainAvailability &&
                                                                    _vm
                                                                      .domainResults
                                                                      .list
                                                                      .length ===
                                                                      0,
                                                                  loading:
                                                                    _vm
                                                                      .domainResults
                                                                      .isBusy,
                                                                  hasFeature:
                                                                    _vm.hasFeatureDomainAvailability,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "content",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm.hasFeatureDomainAvailability
                                                                              ? [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-row justify-space-around",
                                                                                        },
                                                                                        [
                                                                                          _vm
                                                                                            .domainSummary
                                                                                            .availabilityRation !==
                                                                                            undefined &&
                                                                                          _vm
                                                                                            .domainSummary
                                                                                            .availabilityRation !==
                                                                                            null
                                                                                            ? _c(
                                                                                                "Label",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      text: _vm.$t(
                                                                                                        "createBrand.dashboards.risk"
                                                                                                      ),
                                                                                                      location:
                                                                                                        "bottom",
                                                                                                      align:
                                                                                                        "center",
                                                                                                      offsetY: 12,
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "radial-bar",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          width: 130,
                                                                                                          height: 130,
                                                                                                          label:
                                                                                                            _vm.$t(
                                                                                                              "createBrand.dashboards.risk"
                                                                                                            ),
                                                                                                          value:
                                                                                                            _vm
                                                                                                              .domainSummary
                                                                                                              .countryDomains &&
                                                                                                            _vm
                                                                                                              .domainSummary
                                                                                                              .countryDomains
                                                                                                              .registered &&
                                                                                                            _vm
                                                                                                              .domainSummary
                                                                                                              .countryDomains
                                                                                                              .registered >
                                                                                                              0
                                                                                                              ? 100
                                                                                                              : 100 -
                                                                                                                _vm
                                                                                                                  .domainSummary
                                                                                                                  .availabilityRation *
                                                                                                                  100,
                                                                                                          showValue: true,
                                                                                                          startAngle:
                                                                                                            -135,
                                                                                                          endAngle: 135,
                                                                                                          valueColors:
                                                                                                            _vm.riskColors,
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _vm
                                                                                            .domainSummary
                                                                                            .countryDomains &&
                                                                                          _vm
                                                                                            .domainSummary
                                                                                            .countryDomains
                                                                                            .registered !==
                                                                                            undefined &&
                                                                                          _vm
                                                                                            .domainSummary
                                                                                            .countryDomains
                                                                                            .registered !==
                                                                                            null
                                                                                            ? _c(
                                                                                                "Counter",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      titleSingular:
                                                                                                        _vm.$t(
                                                                                                          "createBrand.dashboards.conflict"
                                                                                                        ),
                                                                                                      titlePlural:
                                                                                                        _vm.$t(
                                                                                                          "createBrand.dashboards.conflicts"
                                                                                                        ),
                                                                                                      criticalNumber: 1,
                                                                                                      count:
                                                                                                        _vm
                                                                                                          .domainSummary
                                                                                                          .countryDomains
                                                                                                          .registered,
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-layout",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              column:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm
                                                                                            .domainSummary
                                                                                            .countryDomains
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex flex-row justify-space-between",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-1",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "createBrand.domainSummary.countryDomainsCount"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      class:
                                                                                                        {
                                                                                                          "body-1 font-weight-bold": true,
                                                                                                          "color-ok":
                                                                                                            _vm
                                                                                                              .domainSummary
                                                                                                              .countryDomains
                                                                                                              .registered ===
                                                                                                            0,
                                                                                                          "color-risky":
                                                                                                            _vm
                                                                                                              .domainSummary
                                                                                                              .countryDomains
                                                                                                              .registered >
                                                                                                            0,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .domainSummary
                                                                                                            .countryDomains
                                                                                                            .registered
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _vm
                                                                                            .domainSummary
                                                                                            .commonDomains
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex flex-row justify-space-between",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-1",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "createBrand.domainSummary.commercialDomainsCount"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      class:
                                                                                                        {
                                                                                                          "body-1 font-weight-bold": true,
                                                                                                          "color-ok":
                                                                                                            _vm
                                                                                                              .domainSummary
                                                                                                              .commonDomains
                                                                                                              .registered ===
                                                                                                            0,
                                                                                                          "color-risky":
                                                                                                            _vm
                                                                                                              .domainSummary
                                                                                                              .commonDomains
                                                                                                              .registered >
                                                                                                            0,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .domainSummary
                                                                                                            .commonDomains
                                                                                                            .registered
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _vm
                                                                                            .domainSummary
                                                                                            .otherDomains
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex flex-row justify-space-between",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "body-1",
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "createBrand.domainSummary.otherDomainsCount"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    {
                                                                                                      class:
                                                                                                        {
                                                                                                          "body-1 font-weight-bold": true,
                                                                                                          "color-ok":
                                                                                                            _vm
                                                                                                              .domainSummary
                                                                                                              .otherDomains
                                                                                                              .registered ===
                                                                                                            0,
                                                                                                          "color-risky":
                                                                                                            _vm
                                                                                                              .domainSummary
                                                                                                              .otherDomains
                                                                                                              .registered >
                                                                                                            0,
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm
                                                                                                            .domainSummary
                                                                                                            .otherDomains
                                                                                                            .registered
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              : [
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "pt-1",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex flex-row justify-space-around",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "Label",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  text: _vm.$t(
                                                                                                    "createBrand.dashboards.risk"
                                                                                                  ),
                                                                                                  location:
                                                                                                    "bottom",
                                                                                                  align:
                                                                                                    "center",
                                                                                                  offsetY: 12,
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "radial-bar",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      width: 130,
                                                                                                      height: 130,
                                                                                                      label:
                                                                                                        _vm.$t(
                                                                                                          "createBrand.dashboards.risk"
                                                                                                        ),
                                                                                                      value: 50,
                                                                                                      showValue: true,
                                                                                                      startAngle:
                                                                                                        -135,
                                                                                                      endAngle: 135,
                                                                                                      valueColors:
                                                                                                        _vm.riskColors,
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _c(
                                                                                            "Counter",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  titleSingular:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.conflict"
                                                                                                    ),
                                                                                                  titlePlural:
                                                                                                    _vm.$t(
                                                                                                      "createBrand.dashboards.conflicts"
                                                                                                    ),
                                                                                                  criticalNumber: 1,
                                                                                                  count: 1,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-divider",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mx-3",
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card-text",
                                                                                    {
                                                                                      staticClass:
                                                                                        "fill-height",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-layout",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              column:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex flex-row justify-space-between",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createBrand.domainSummary.countryDomainsCount"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1 font-weight-bold color-risky",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "1"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex flex-row justify-space-between",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createBrand.domainSummary.commercialDomainsCount"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1 font-weight-bold color-risky",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "1"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "d-flex flex-row justify-space-between",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "createBrand.domainSummary.otherDomainsCount"
                                                                                                      )
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "body-1 font-weight-bold color-risky",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "1"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-loading",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.loading"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "content-nodata",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createBrand.dashboards.nodata"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "actions",
                                                                        fn: function () {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    text: "",
                                                                                    disabled:
                                                                                      !_vm.hasFeatureDomainAvailability ||
                                                                                      _vm
                                                                                        .domainResults
                                                                                        .list
                                                                                        .length ===
                                                                                        0,
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      _vm.showDomainResults = true
                                                                                    },
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        left: "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fa-light fa-grid-2"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "createBrand.dashboards.buttonDetails"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "thumbs-buttons",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    tooltip:
                                                                                      "bottom",
                                                                                    small:
                                                                                      "",
                                                                                    context:
                                                                                      "Create Brand Domain Summary",
                                                                                    disabled:
                                                                                      !_vm.hasFeatureDomainAvailability ||
                                                                                      !_vm
                                                                                        .brandQueryResult
                                                                                        .object,
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    3041813252
                                                                  ),
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.$vuetify.breakpoint.lgAndUp &&
                                        _vm.brandQueryResult.object &&
                                        _vm.brandQueryResult.object.items
                                          ? _c(
                                              "v-row",
                                              { attrs: { id: "searchresult" } },
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "section-title decorative-border-left",
                                                      },
                                                      [
                                                        _c("h2", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createBrand.brandQueryResults.title"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                        _c("h3", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "createBrand.brandQueryResults.subtitle"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-toolbar",
                                                      {
                                                        staticClass: "px-0",
                                                        attrs: {
                                                          dense: "",
                                                          light: "",
                                                          flat: "",
                                                          "max-height": "48",
                                                        },
                                                      },
                                                      [
                                                        _vm.$vuetify.breakpoint
                                                          .lgAndUp
                                                          ? _c("v-spacer")
                                                          : _vm._e(),
                                                        _vm.$vuetify.breakpoint
                                                          .mdAndUp
                                                          ? _c(
                                                              "v-btn-toggle",
                                                              {
                                                                attrs: {
                                                                  mandatory: "",
                                                                  dense: "",
                                                                  group: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.activeBrandQueryResultView,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.activeBrandQueryResultView =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "activeBrandQueryResultView",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "carousel-view",
                                                                      text: "",
                                                                      disabled:
                                                                        !_vm
                                                                          .brandQueryResult
                                                                          .object ||
                                                                        !_vm
                                                                          .brandQueryResult
                                                                          .object
                                                                          .items ||
                                                                        _vm
                                                                          .brandQueryResult
                                                                          .object
                                                                          .items
                                                                          .length ===
                                                                          0,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fa-light fa-rectangle-vertical-history"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "vertical-view",
                                                                      text: "",
                                                                      disabled:
                                                                        !_vm
                                                                          .brandQueryResult
                                                                          .object ||
                                                                        !_vm
                                                                          .brandQueryResult
                                                                          .object
                                                                          .items ||
                                                                        _vm
                                                                          .brandQueryResult
                                                                          .object
                                                                          .items
                                                                          .length ===
                                                                          0,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fa-light fa-grid"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.brandQueryResult
                                                      .object &&
                                                    _vm.brandQueryResult.object
                                                      .items &&
                                                    _vm.brandQueryResult.object
                                                      .items.length > 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column pb-2",
                                                          },
                                                          [
                                                            _c(
                                                              "Carousel3dSlider",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.activeBrandQueryResultView ===
                                                                      "carousel-view",
                                                                    expression:
                                                                      "activeBrandQueryResultView === 'carousel-view'",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  loop: true,
                                                                  width: 400,
                                                                  height: 800,
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      _vm._l(
                                                                        _vm
                                                                          .brandQueryResult
                                                                          .object
                                                                          .items,
                                                                        function (
                                                                          brand,
                                                                          index
                                                                        ) {
                                                                          return {
                                                                            key:
                                                                              "slide-" +
                                                                              index,
                                                                            fn: function () {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    key: index,
                                                                                    staticClass:
                                                                                      "pa-1",
                                                                                    staticStyle:
                                                                                      {
                                                                                        width:
                                                                                          "100%",
                                                                                        height:
                                                                                          "100%",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "VerticalBrandTile",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            width:
                                                                                              "100%",
                                                                                            height:
                                                                                              "100%",
                                                                                            brand:
                                                                                              brand,
                                                                                            showGoodsServices: true,
                                                                                            canAddToBookmarks: true,
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          }
                                                                        }
                                                                      ),
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            ),
                                                            _c(
                                                              "VerticalBrandTileGrid",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.activeBrandQueryResultView ===
                                                                      "vertical-view",
                                                                    expression:
                                                                      "activeBrandQueryResultView === 'vertical-view'",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  brands:
                                                                    _vm
                                                                      .brandQueryResult
                                                                      .object
                                                                      .items,
                                                                  showGoodsServices: true,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fill-height",
                                                            staticStyle: {
                                                              "padding-top":
                                                                "100px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-center",
                                                                staticStyle: {
                                                                  position:
                                                                    "sticky",
                                                                  top: "50%",
                                                                  transform:
                                                                    "translateY(-50%)",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "16px",
                                                                        "font-weight":
                                                                          "900",
                                                                      },
                                                                    attrs: {
                                                                      color:
                                                                        "tertiary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "fa-light fa-face-thinking"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "tertiary--text",
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "16px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "createBrand.brandQueryResults.noBrandFound"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Popup",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "createBrand.brandQueryResults.title"
                                          ),
                                          icon: _vm.$braendz.getBrandTypeIcon(
                                            "Combined"
                                          ),
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "toolbar-append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        bottom: "",
                                                        left: "",
                                                        "close-on-content-click": false,
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          icon: "",
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "fa-light fa-gears"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3666502836
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "transparent",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item",
                                                            [
                                                              _c(
                                                                "v-list-item-action",
                                                                [
                                                                  _c(
                                                                    "v-switch",
                                                                    {
                                                                      attrs: {
                                                                        inset:
                                                                          "",
                                                                        flat: "",
                                                                        disabled:
                                                                          _vm
                                                                            .brandQueryResult
                                                                            .isBusy,
                                                                        "hide-details":
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.updateBrandQueryResult,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.enableNiceClassFilter,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.enableNiceClassFilter =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "enableNiceClassFilter",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item-title",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "createBrand.brandQueryResults.enableNiceClassFilter"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-action",
                                                                [
                                                                  _c(
                                                                    "ExplainButton",
                                                                    {
                                                                      attrs: {
                                                                        icon: true,
                                                                        color:
                                                                          "amber darken-2",
                                                                        chatInstance:
                                                                          _vm.chatInstance,
                                                                        message:
                                                                          _vm.$t(
                                                                            "createBrand.brandQueryResults.enableNiceClassFilterExplainMessage"
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        posted:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.chatOpened = true
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          2311701217
                                        ),
                                        model: {
                                          value: _vm.showBrandQueryResultPopup,
                                          callback: function ($$v) {
                                            _vm.showBrandQueryResultPopup = $$v
                                          },
                                          expression:
                                            "showBrandQueryResultPopup",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-container",
                                              { attrs: { fluid: "" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c("v-col", [
                                                      _vm.brandQueryResult
                                                        .object &&
                                                      _vm.brandQueryResult
                                                        .object.items &&
                                                      _vm.brandQueryResult
                                                        .object.items.length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "pb-2",
                                                            },
                                                            [
                                                              _c(
                                                                "VerticalBrandTileGrid",
                                                                {
                                                                  attrs: {
                                                                    brands:
                                                                      _vm
                                                                        .brandQueryResult
                                                                        .object
                                                                        .items,
                                                                    showGoodsServices: true,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "fill-height",
                                                              staticStyle: {
                                                                "padding-top":
                                                                  "100px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-center",
                                                                  staticStyle: {
                                                                    position:
                                                                      "sticky",
                                                                    top: "50%",
                                                                    transform:
                                                                      "translateY(-50%)",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "16px",
                                                                          "font-weight":
                                                                            "900",
                                                                        },
                                                                      attrs: {
                                                                        color:
                                                                          "tertiary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "fa-light fa-face-thinking"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "tertiary--text",
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "16px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "createBrand.brandQueryResults.noBrandFound"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "Popup",
                                      {
                                        attrs: {
                                          title: _vm.$t(
                                            "createBrand.domainResults.title"
                                          ),
                                          icon: "fa-light fa-globe-pointer",
                                          maxWidth: 800,
                                        },
                                        model: {
                                          value: _vm.showDomainResults,
                                          callback: function ($$v) {
                                            _vm.showDomainResults = $$v
                                          },
                                          expression: "showDomainResults",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card-subtitle",
                                          { staticClass: "pt-5" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "createBrand.domainResults.subtitle"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        !_vm.domainResults.isBusy &&
                                        _vm.domainResults.list.length > 0
                                          ? _c(
                                              "v-card-text",
                                              _vm._l(
                                                _vm.domainResults.list,
                                                function (domain, index) {
                                                  return _c("DomainChip", {
                                                    key: index,
                                                    staticClass: "ma-2",
                                                    attrs: {
                                                      available:
                                                        domain.available,
                                                      domain: domain.name,
                                                      premium: domain.premium,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm.domainResults.isBusy
                                          ? _c(
                                              "v-card-text",
                                              {
                                                staticClass:
                                                  "flex-grow-1 align-content-center text-center",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "createBrand.domainResults.searching"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "v-container",
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-center",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "section-title decorative-border-bottom text-center",
                          },
                          [
                            _c("h1", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("createBrand.missingFeature.title")
                                )
                              ),
                            ]),
                            _c("h3", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("createBrand.missingFeature.subtitle")
                                )
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                !_vm.userAccount
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "d-flex flex-column justify-center text-center",
                            attrs: { cols: "12" },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "createBrand.missingFeature.loginText"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "v-btn",
                              {
                                attrs: { plain: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$auth.signIn()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("fa-light fa-right-to-bracket"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "createBrand.missingFeature.buttonLogin"
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "d-flex flex-column justify-center text-center",
                            attrs: { cols: "12" },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "createBrand.missingFeature.trialQuotaExhausted"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "createBrand.missingFeature.subscribeText"
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "v-btn",
                              { attrs: { plain: "", to: { name: "Pricing" } } },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("fa-light fa-cart-shopping"),
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "createBrand.missingFeature.buttonPricing"
                                      )
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
        _c("ChatAside", {
          attrs: {
            instance: _vm.chatInstance,
            position: _vm.$vuetify.breakpoint.lgAndUp ? "sticky" : "fixed",
            elevation: 1,
            "max-width": _vm.$vuetify.breakpoint.lgAndUp
              ? "25%"
              : _vm.$vuetify.breakpoint.md
              ? "50%"
              : "100%",
          },
          model: {
            value: _vm.chatOpened,
            callback: function ($$v) {
              _vm.chatOpened = $$v
            },
            expression: "chatOpened",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }