var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", [
    _c(
      "section",
      { attrs: { id: "content" } },
      [
        _c(
          "v-container",
          [
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c("h1", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.$t("privacy.headline"))),
                  ]),
                ]),
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.$t("privacy.content")) },
                  }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }